export default {
  async insert (Vue, formData, idtecnico) {
    return await Vue.$online.parteTrabajo.insert(
      {
        idsistema: formData.sistema.idsistema,
        observacion: formData.observacion,
        idtactuacion: formData.idtactuacion.idtactuacion,
        idtincidencia_ot: formData.idtincidencia_ot,
        idempresa: formData.idempresa,
        idtecnico,
      },
      formData.idsubsis_afectados,
      formData.agregarMaterialAfectado,
      formData.crearParteTrabajo,
    )
  }
}
