var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b10-base',[_c('ValidationProvider',{attrs:{"name":"sistema","rules":_vm.formRules.sistema},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"loading":_vm.isLoading,"items":_vm.sistemas,"item-text":_vm.$online.sistema.title,"label":"Sistema","clearable":"","error-messages":errors,"search-input":_vm.sistemaSearch,"return-object":"","no-filter":"","disabled":!!_vm.idsistema},on:{"update:searchInput":function($event){_vm.sistemaSearch=$event},"update:search-input":function($event){_vm.sistemaSearch=$event},"change":_vm.sistemaSelected,"click:clear":_vm.clearSistemas},scopedSlots:_vm._u([{key:"item",fn:function(slotProps){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(_vm.$online.sistema.title(slotProps.item))+" ")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(_vm.$online.sistema.subtitle(slotProps.item))+" ")])],1)]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endIntersect),expression:"endIntersect"}]})]},proxy:true}],null,true),model:{value:(_vm.form.sistema),callback:function ($$v) {_vm.$set(_vm.form, "sistema", $$v)},expression:"form.sistema"}})]}}])}),(_vm.form.sistema)?_c('b10-autocomplete-alert',[_vm._v(" "+_vm._s(_vm.$online.sistema.title(_vm.form.sistema))),_c('br'),_vm._v(_vm._s(_vm.$online.sistema.subtitle(_vm.form.sistema))+" ")]):_vm._e(),_c('ValidationProvider',{attrs:{"name":"idempresa","rules":_vm.formRules.idempresa},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.empresas.length > 0)?_c('b10-autocomplete',{attrs:{"items":_vm.empresas,"item-value":"idempresa","item-text":"nombre","label":"Empresa","clearable":"","error-messages":errors},model:{value:(_vm.form.idempresa),callback:function ($$v) {_vm.$set(_vm.form, "idempresa", $$v)},expression:"form.idempresa"}}):_vm._e()]}}])}),_c('ValidationProvider',{attrs:{"name":"idtactuacion","rules":_vm.formRules.idtactuacion},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.tactuaciones,"item-value":"idtactuacion","item-text":"tactuacion_descripcion","label":"Tipo de actuación","clearable":"","error-messages":errors,"return-object":""},on:{"change":_vm.changeIdtactuacion},model:{value:(_vm.form.idtactuacion),callback:function ($$v) {_vm.$set(_vm.form, "idtactuacion", $$v)},expression:"form.idtactuacion"}})]}}])}),_c('b10-autocomplete',{attrs:{"items":_vm.tincidenciaOts,"item-value":"idtincidencia_ot","item-text":"descripcion","label":"Tipo de incidencia","clearable":""},model:{value:(_vm.form.idtincidencia_ot),callback:function ($$v) {_vm.$set(_vm.form, "idtincidencia_ot", $$v)},expression:"form.idtincidencia_ot"}}),_c('ValidationProvider',{attrs:{"name":"observacion","rules":_vm.formRules.observacion},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-textarea',{attrs:{"label":"Comentario","error-messages":errors},model:{value:(_vm.form.observacion),callback:function ($$v) {_vm.$set(_vm.form, "observacion", $$v)},expression:"form.observacion"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"idsubsis_afectados","rules":_vm.formRules.idsubsis_afectados},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.subsistemas,"item-value":"idsubsis","item-text":"descripcion","label":"Subsistemas afectados","clearable":"","error-messages":errors,"multiple":"","chips":"","deletable-chips":""},model:{value:(_vm.form.idsubsis_afectados),callback:function ($$v) {_vm.$set(_vm.form, "idsubsis_afectados", $$v)},expression:"form.idsubsis_afectados"}})]}}])}),_c('v-checkbox',{attrs:{"label":"Agregar material afectado de los subsistemas seleccionados"},model:{value:(_vm.form.agregarMaterialAfectado),callback:function ($$v) {_vm.$set(_vm.form, "agregarMaterialAfectado", $$v)},expression:"form.agregarMaterialAfectado"}}),_c('v-checkbox',{attrs:{"label":"Asignarme el parte de trabajo"},model:{value:(_vm.form.crearParteTrabajo),callback:function ($$v) {_vm.$set(_vm.form, "crearParteTrabajo", $$v)},expression:"form.crearParteTrabajo"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }